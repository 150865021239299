<template>
	<div class="o-row o-row--sm">
		<div class="o-container o-container--lg">
			<div class="c-overview">
				<SearchFilters @toggleMobileMap="toggleMobileMap()" />
				<hr class="c-hr--dark" />
				<div class="o-row o-row--sm">
					<div class="o-container o-container--lg">
						<div class="grid">
							<div class="row">
								<div class="col-xl-4 col-lg-5">
									<h3>{{ machines.length }} automaten gevonden</h3>
									<span class="c-overview__location">{{
										!!filters.location.formattedAddress ? filters.location.formattedAddress : ""
									}}</span>
									<div class="c-overview__machines" :class="{ 'u-desktop-hide': showMobileMap }">
										<div v-if="machines.length > 0 && categories.length > 0">
											<div v-if="!loadingState">
												<MachineCard
													v-for="machine in machines"
													:key="machine.vending_machine_id"
													:machine="machine"
												/>
											</div>
										</div>
										<Loader v-if="loadingState" :is-loading="true" :color="'spinner--red'" />
									</div>
								</div>
								<div class="col-xl-8 col-lg-7">
									<div class="c-overview__map" :class="{ 'u-desktop-hide': !showMobileMap }">
										<OverviewMap
											@open-card="openCard"
											@fetchMachinesMapCenter="fetchMachinesMapCenter"
										/>
										<div class="c-overview__map--popup">
											<MachineCard
												v-if="selectedMachine.vending_machine_id"
												:key="selectedMachine.vending_machine_id"
												:machine="selectedMachine"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script src="./Overview.controller.js"></script>
<style lang="scss" src="./Overview.styles.scss"></style>
