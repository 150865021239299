import { mapMutations, mapGetters } from "vuex";
import _ from "lodash";

export const updateStateByRouteQuery = {
	computed: {
		...mapGetters("machine", {
			filters: "getFilters",
			categories: "getCategories",
			paymentMethods: "getPaymentMethods",
			locations: "getLocations",
			openingTimes: "getOpeningTimes",
		}),
	},
	methods: {
		...mapMutations("machine", {
			clearMachineFilters: "CLEAR_MACHINE_FILTERS",
			setSearchLocation: "SET_SEARCH_LOCATION",
		}),
		updateStateByRouteQuery() {
			if (!_.isEmpty(this.$route.query)) {
				if (this.$route.query.categories) {
					const categories = this.$route.query.categories.split(",");
					categories.forEach((categoryName) => {
						this.setMachineFilters(this.categories.find((c) => c.name.toLowerCase() === categoryName));
					});
				}
				if (this.$route.query.paymentmethods) {
					const paymentMethods = this.$route.query.paymentmethods.split(",");
					paymentMethods.forEach((paymentMethodName) => {
						this.setMachineFilters(
							this.paymentMethods.find((pm) => pm.name.toLowerCase() === paymentMethodName)
						);
					});
				}
				if (this.$route.query.locations) {
					const locations = this.$route.query.locations.split(",");
					locations.forEach((locationName) => {
						this.setMachineFilters(this.locations.find((pm) => pm.name.toLowerCase() === locationName));
					});
				}
				if (this.$route.query.openingTimes) {
					const openingTimes = this.$route.query.openingTimes.split(",");
					openingTimes.forEach((openingTimeValue) => {
						this.setMachineFilters(
							this.openingTimes.find((pm) => pm.name.toLowerCase() === openingTimeValue)
						);
					});
				}
				if (this.$route.query.longitude && this.$route.query.latitude && this.$route.query.address) {
					let location = {};
					location.longitude = this.$route.query.longitude;
					location.latitude = this.$route.query.latitude;
					location.formattedAddress = this.$route.query.address;

					this.setSearchLocation(location);
				}
			}
		},
	},
};
