import { SearchFilters, MachineCard, OverviewMap, Loader } from "@/components";
import { mapActions, mapMutations, mapGetters } from "vuex";
import { updateRouteQueryByState } from "../../mixins/UpdateRouteQueryByState";
import { updateStateByRouteQuery } from "../../mixins/UpdateStateByRouteQuery";

export default {
	name: "Overview",
	components: {
		SearchFilters,
		MachineCard,
		OverviewMap,
		Loader,
	},
	mixins: [updateRouteQueryByState, updateStateByRouteQuery],
	data: () => ({
		location: "",
		selectedMachine: {},
		showMobileMap: false,
	}),
	computed: {
		...mapGetters("machine", {
			machines: "getMachines",
			filters: "getFilters",
			categories: "getCategories",
			loadingState: "getLoadingState",
			firstLoad: "getFirstLoad",
		}),
	},
	methods: {
		...mapActions("machine", {
			fetchCategories: "FETCH_CATEGORIES",
			fetchPaymentMethods: "FETCH_PAYMENT_METHODS",
			fetchLocations: "FETCH_LOCATIONS",
			fetchOpeningTimes: "FETCH_OPENING_TIMES",
			fetchMachines: "FETCH_DATA_MACHINES",
		}),
		...mapMutations("machine", {
			setMachineFilters: "SET_MACHINE_FILTERS",
			clearMachineFilters: "CLEAR_MACHINE_FILTERS",
			setSearchLocation: "SET_SEARCH_LOCATION",
		}),
		openCard(machineId) {
			if (machineId) {
				this.selectedMachine = this.machines.find((m) => m.vending_machine_id === machineId);
			}
		},
		fetchMachinesMapCenter(latLong) {
			let location = {};
			location.latitude = latLong.lng;
			location.longitude = latLong.lat;

			this.setSearchLocation(location);
		},
		toggleMobileMap() {
			this.showMobileMap = !this.showMobileMap;
		},
	},
	watch: {
		filters: {
			deep: true,
			handler(value) {
				this.updateRouteQueryByState();
			},
		},
		"filters.location": function (newVal, oldVal) {
			this.fetchMachines(this.filters);
		},
	},

	async created() {
    await Promise.all([this.fetchCategories(), this.fetchPaymentMethods(), this.fetchOpeningTimes(), this.fetchLocations()]);
    if (!this.firstLoad) this.updateStateByRouteQuery();
	  this.fetchMachines(this.filters);
	},
};
